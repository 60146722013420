import { Link } from 'gatsby';
import React from 'react';
import * as styles from '../Blog/styles/blog.module.css';

export default function BlogCareer({ ...props }) {
  return (
    <>
      {/* <div id='career'>
        <div className={styles.blog_wrapper}>
          <div className={styles.blog_inner}>
            <h3 className={styles.blog_subheader}>Career {'&'} Growth</h3>
            <Link to={'/Blog/blogPost/' + props.data.slug} className={styles.seeAll}>SEE ALL</Link>
          </div>
          <div>
            <div className={styles.blog_inner_wrapper}>
              {props.data.map((blog) => (
                <>
                  <div key={blog.id} className={styles.blog_content}>
                    <img src={blog.img} alt='' />
                    <p className={styles.blog_subtopic}>{blog.subTopic}</p>
                    <p className={styles.blog_topic}>{blog.topic}</p>
                    <p className={styles.blog_text}>{blog.content}</p>
                    <div className={styles.authordetails}>
                      <img src={blog.authorimg} alt='' />
                      <p className={styles.author}>{blog.author}</p>
                    </div>
                    <p className={styles.blog_date}>{blog.Date}</p>
                  </div>
                </>
              ))}
            </div>
            <p className={styles.viewmore}>SEE ALL</p>
          </div>
        </div>
      </div> */}
    </>
  );
}
